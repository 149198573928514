import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import { INITIAL_STATE } from "../whd-form-state";
import { InputRadioYesNo } from "../../inputComponents/InputRadio";
import React, { useState } from "react";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import {
  _handleBack,
  fieldReqError,
  handleInputChange,
  onSubmitWithState,
} from "../reusableFunctions/util";

export const FormPension = ({ stateService }: { stateService: any }) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => onSubmitWithState(send, state)(data);

  const [formData, setFormData] =
    useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription
              value="Your current benefits"
              description="Pension"
            />
          </p>
          <p className="right brand-midnight">Step 7 of 9</p>
        </div>

        <InputRadioYesNo
          required
          name="q9"
          question="Do you (or your partner) get Contribution based Employment and Support Allowance?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q9 : ""}
        />

        <InputRadioYesNo
          required
          name="q14"
          question="Do you (or your partner) get a pensioner premium (all rates)?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q14 : ""}
        />

        <InputRadioYesNo
          required
          name="q17"
          question="Do you (or your partner) get Support or Work Related activity component?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q17 : ""}
        />

        <InputRadioYesNo
          required
          name="q2"
          question="Do you (or your partner) get Pension Credit and are not included in the Core Group?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q2 : ""}
        />
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
