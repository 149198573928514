import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { InputSelector } from "../../inputComponents/InputSelector";
import { InputText } from "../../inputComponents/InputText";
import { InputRadioLabel } from "../../inputComponents/InputRadio";
import { InputCheckBox } from "../../inputComponents/InputCheckBox";
import { FormData } from "../formData/FormData";
import { INITIAL_STATE } from "../whd-pre-reg-state";
import { StateAction } from "../../util/stateUtil";
import {
  fieldReqError,
  handleInputChange,
  handleInputCheckChange,
} from "../../whd-form/reusableFunctions/util";

export const FormCustomerDetails = ({
  stateService,
}: {
  stateService: any;
}) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...state.context },
  });

  const [formData, setFormData] = useState<FormData>(INITIAL_STATE);

  const onSubmit = (data: any) => {
    const stateContext = state.context;

    const prevState = {
      accountId: stateContext.accountId,
      postcode: stateContext.postcode,
      disclaimer: stateContext.disclaimer,
    };

    const payload = { ...prevState, ...data };
    send(StateAction.NEXT, payload);
  };

  const handleBack = () => {
    const stateContext = state.context;

    const payload = {
      accountId: stateContext.accountId,
      postcode: stateContext.postcode,
      disclaimer: stateContext.disclaimer,
      title: formData.title,
      firstName: formData.firstName,
      lastName: formData.lastName,
      address1: formData.address1,
      address2: formData.address2,
      smartMeterInterest: formData.smartMeterInterest,
      dualFuel: formData.dualFuel,
      guaranteedCredit: formData.guaranteedCredit,
      declaration: formData.declaration,
    };
    send(StateAction.PREV, payload);
  };

  return (
    <form id={"formData"} onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <b>Your personal data and energy plan</b>
          </p>
          <p className="right brand-midnight">Step 2 of 2</p>
        </div>

        <InputSelector
          label="Title"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          required
          placeholder="Title"
          onChange={handleInputChange(formData, setFormData)}
          options={["Mr", "Mrs", "Ms", "Miss", "Dr", "Mx"]}
          defaultValue={state ? state.context.title : ""}
        />

        <InputText
          required
          label="First Name"
          name="firstName"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.firstName : ""}
        />

        <InputText
          required
          label="Last Name"
          name="lastName"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.lastName : ""}
        />

        <InputText
          required
          label="Address Line 1"
          name="Address 1"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.address1 : ""}
        />

        <InputText
          label="Address Line 2 (optional)"
          name="Address 2"
          register={register}
          errors={errors}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.address2 : ""}
        />

        <InputRadioLabel
          required
          options={[
            { label: "Electricity and Gas", value: "yes" },
            { label: "Just electricity", value: "no" },
          ]}
          name="dualFuel"
          question="Your OVO Energy Account"
          details="Remember, Warm Home Discount is only for dual-fuel and electricity customers. If you’re a gas-only customer, you’ll need to apply through your electricity supplier"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          isChecked={state ? state.context.dualFuel : false}
          onChange={handleInputCheckChange(formData, setFormData)}
        />

        <InputRadioLabel
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            { label: "I don't know", value: "idk" },
          ]}
          name="guaranteedCredit"
          question="Do you receive the Guaranteed Credit element of Pension Credit?"
          details="Customers who receive the Guaranteed Credit element of Pension Credit are eligible for the Core Group scheme"
          register={register}
          isChecked={state ? state.context.guaranteedCredit : false}
          onChange={handleInputCheckChange(formData, setFormData)}
        />

        <InputCheckBox
          label="smartMeterInterest"
          statement={[<b>Yes - please contact me with more information</b>]}
          question="Are you interested in a Smart Meter?"
          details={[
            "This webpage tells you all you need to know about ",
            <a
              href="https://www.ovoenergy.com/smart-meters"
              target="_blank"
              rel="noreferrer"
              className="hyperlink-color"
            >
              Smart Meters
            </a>,
            ".",
          ]}
          register={register}
          onChange={handleInputCheckChange(formData, setFormData)}
          isChecked={state ? state.context.smartMeterInterest : false}
        />

        <InputCheckBox
          required
          errorMessage={fieldReqError}
          label="declaration"
          question=""
          statement={[
            <p>
              <b>
                I understand this is not an application for Warm Home Discount.
              </b>{" "}
              <br />
              <i>
                By filling in this form you are ensuring that you will be the
                first group of people contacted when the Warm Home Discount
                application window opens.
              </i>
            </p>,
          ]}
          register={register}
          errors={errors}
          onChange={handleInputCheckChange(formData, setFormData)}
          isChecked={state ? state.context.declaration : false}
        />
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
