import React, { useState } from "react";
import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import "../../form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { InputCheckBox } from "../../inputComponents/InputCheckBox";
import { StateAction } from "../../util/stateUtil";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { INITIAL_STATE } from "../whd-form-state";
import { _handleBack } from "../reusableFunctions/util";

const { REACT_APP_PRODUCT } = process.env;

export const FormDeclaration = ({ stateService }: { stateService: any }) => {
  const [state, send]: any = useService(stateService);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data: any) => {
    send(StateAction.NEXT, { ...data });
  };

  const [formData] = useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription value="Acknowledgements" />
          </p>
          <p className="right brand-midnight">Step 9 of 9</p>
        </div>

        <div>
          <InputCheckBox
            label={`I agree to ${REACT_APP_PRODUCT} asking the Department for Work and Pensions (DWP) and Auriga Services to confirm that I get a qualifying benefit`}
            required
            name="declaration1"
            statement={<b>I agree</b>}
            question={
              <p>
                I understand that {REACT_APP_PRODUCT} may need to check that I'm
                eligible for the Warm Home Discount Scheme. I agree to{" "}
                {REACT_APP_PRODUCT} asking the Department for Work and Pensions
                (DWP) and Auriga Services to confirm that I get a qualifying
                benefit.
              </p>
            }
            errors={errors}
            errorMessage="You need to agree in order to proceed."
            register={register}
          />
        </div>

        <div>
          <InputCheckBox
            label={`I agree to send ${REACT_APP_PRODUCT} copies of my benefit letters`}
            required
            name="declaration2"
            statement={<b>I agree</b>}
            question={
              <p>
                I understand that if the DWP or Auriga Service cannot confirm
                that I get the qualifying benefits to get the Warm Home
                Discount, I may need to send {REACT_APP_PRODUCT} copies of my
                benefit letters.
              </p>
            }
            errors={errors}
            errorMessage="You need to agree in order to proceed."
            register={register}
          />
        </div>

        <div>
          <InputCheckBox
            label="I understand that if I change supplier before my discount is paid I won't be eligible"
            required
            name="declaration3"
            statement={<b>I agree</b>}
            question={
              <p>
                I understand that if I change supplier before my discount is
                paid I won't be eligible.
              </p>
            }
            errors={errors}
            errorMessage="You need to agree in order to proceed."
            register={register}
          />
        </div>
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
