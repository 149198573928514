import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import { INITIAL_STATE } from "../whd-form-state";
import { InputRadioYesNo } from "../../inputComponents/InputRadio";
import React, { useState } from "react";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import {
  _handleBack,
  fieldReqError,
  handleInputChange,
  onSubmitWithState,
} from "../reusableFunctions/util";

export const FormChildBenefits = ({ stateService }: { stateService: any }) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => onSubmitWithState(send, state)(data);

  const [formData, setFormData] =
    useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription
              value="Your current benefits"
              description="Do you have children residing at your property?"
            />
          </p>
          <p className="right brand-midnight">Step 3 of 9</p>
        </div>

        <InputRadioYesNo
          required
          name="q1"
          question="Do you (or your partner) get Free School Meals for children born on or before 1 April 2016?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q1 : ""}
        />

        <InputRadioYesNo
          required
          name="q7"
          question="Do you (or your partner) get Child Tax Credit on an income of £19,978 or less (before tax)?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q7 : ""}
        />

        <InputRadioYesNo
          required
          name="q13"
          question="Do you (or your partner) have parental responsibility for a child born on or after 1 April 2019 who ordinarily resides with you?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q13 : ""}
        />

        <InputRadioYesNo
          required
          name="q22"
          question="Do you (or your partner) have parental responsibility for a child ages 18 years or under and in full time education born on or after 1 April 2006?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q22 : ""}
        />

        <InputRadioYesNo
          required
          name="q21"
          question="Do you (or your partner) get Disabled child element?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q21 : ""}
        />

        <InputRadioYesNo
          required
          name="q19"
          question="Do you (or your partner) get a Child Disability Living Allowance or Child Disability Payment?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q19 : ""}
        />

        <InputRadioYesNo
          required
          name="q20"
          question="Do you (or your partner) get a Child Tax Credit which includes a disability element for a child born on or after 1 April 2005?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q20 : ""}
        />
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
