import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import { INITIAL_STATE } from "../whd-form-state";
import { InputRadioYesNo } from "../../inputComponents/InputRadio";
import React, { useState } from "react";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import {
  _handleBack,
  fieldReqError,
  handleInputChange,
  onSubmitWithState,
} from "../reusableFunctions/util";

export const FormHousing = ({ stateService }: { stateService: any }) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => onSubmitWithState(send, state)(data);

  const [formData, setFormData] =
    useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription
              value="Your current benefits"
              description="Housing"
            />
          </p>
          <p className="right brand-midnight">Step 6 of 9</p>
        </div>

        <InputRadioYesNo
          required
          name="q10"
          question="Do you (or your partner) get Housing Benefit?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q10 : ""}
        />

        <InputRadioYesNo
          required
          name="q26"
          question="Do you (or your partner) get Housing element of Universal Credit or Housing Benefit?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q26 : ""}
        />

        <InputRadioYesNo
          required
          name="q27"
          question="Do you (or your partner) get Council Tax Benefit?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q27 : ""}
        />
      </div>
      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
