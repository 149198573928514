import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { InputRadioWithHint } from "../../inputComponents/InputRadio";
import { DoBInputText, InputText } from "../../inputComponents/InputText";
import { StateAction } from "../../util/stateUtil";
import { INITIAL_STATE } from "../whd-form-state";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { _handleBack, fieldReqError } from "../reusableFunctions/util";

export const MainAccountHolderDetails = ({
  stateService,
}: {
  stateService: any;
}) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...state.context },
  });

  const onSubmit = (data: any) => {
    send(StateAction.NEXT, { ...data });
  };

  const [onBehalfAccountHolder, setOnBehalfAccountHolder] = useState(
    state.context.onBehalfAccountHolder === "yes"
  );
  const [isBenefitClaimant, setIsBenefitClaimant] = useState(
    state.context.isAccountHolderSameAsBenefitClaimant === "yes"
  );

  const [formData] = useState<FormCustomerDetailsData>(INITIAL_STATE);

  function handleOnBehalfAccountHolderChange() {
    setOnBehalfAccountHolder((state) => !state);
  }

  function handleBenefitClaimantChange() {
    setIsBenefitClaimant((state) => !state);
  }

  const handleBack = () => _handleBack(state, formData, send);

  const _options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription value="Account Holder" />
          </p>
          <p className="right brand-midnight">Step 8 of 9</p>
        </div>

        <InputRadioWithHint
          options={_options}
          defaultChoiceIndex={1}
          name="onBehalfAccountHolder"
          question="Are you completing this form on behalf of the account holder?"
          hint="If the person completing the form is not the account holder, we need to capture their details.
          This will help us if we need to contact the claimant for any further details."
          register={register}
          handler={handleOnBehalfAccountHolderChange}
        />

        {onBehalfAccountHolder && (
          <div>
            <InputText
              label="Name of person completing the form"
              name="onBehalfAccountHolderName"
              register={register}
              errors={errors}
              required
              errorMessage={fieldReqError}
            />

            <InputText
              label="Relationship to the account holder"
              name="onBehalfAccountHolderRelationship"
              register={register}
              errors={errors}
              required
              errorMessage={fieldReqError}
            />
          </div>
        )}

        <InputRadioWithHint
          options={_options}
          defaultChoiceIndex={0}
          name="isAccountHolderSameAsBenefitClaimant"
          question="Is the account holder also the benefit claimant?"
          hint="The benefit claimant is the person who claims the benefits you told us to receive. They must be the
          account holder or the partner of the account holder to qualify for the Warm Home Discount.
          If you are not the benefit claimant, please fill in the details as this will help us process your discount."
          register={register}
          handler={handleBenefitClaimantChange}
        />

        {!isBenefitClaimant && (
          <div>
            <InputText
              label="First name of the benefit claimant"
              name="benefitClaimantFirstName"
              register={register}
              errors={errors}
              required
              errorMessage={fieldReqError}
            />

            <InputText
              label="Surname of the benefit claimant"
              name="benefitClaimantSurname"
              register={register}
              errors={errors}
              required
              errorMessage={fieldReqError}
            />

            <DoBInputText
              label="Date of Birth of the benefit claimant"
              name="benefitClaimantDob"
              register={register}
              errors={errors}
              required
              errorMessage={fieldReqError}
            />
          </div>
        )}
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
