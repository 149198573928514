import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { InputText } from "../../inputComponents/InputText";
import { InputSelector } from "../../inputComponents/InputSelector";
import { InputRadio } from "../../inputComponents/InputRadio";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { INITIAL_STATE } from "../whd-form-state";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import {
  _handleBack,
  handleInputChange,
  onSubmit,
} from "../reusableFunctions/util";

export const FormCustomerDetails = ({
  stateService,
}: {
  stateService: any;
}) => {
  const [state, send]: any[] = useService(stateService);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...state.context },
  });

  const [formData, setFormData] =
    useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit(send))}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription value="Your personal data" />
          </p>
          <p className="right brand-midnight">Step 2 of 9</p>
        </div>

        <InputText
          label="Account ID"
          register={register}
          errors={errors}
          required
          disabled={!!state.context.accountId}
          errorMessage="This field is required."
          hint="This is found on your monthly statement. If you're a Pay As You Go customer, you can find your account number on emails or letters from us. Or, it's in your welcome pack."
        />

        <InputSelector
          label="Title"
          register={register}
          errors={errors}
          errorMessage="This field is required."
          required
          placeholder="Title"
          options={["Mr", "Mrs", "Ms", "Miss", "Dr", "Mx"]}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.title : ""}
        />

        <InputText
          required
          label="First Name"
          name="name"
          register={register}
          errors={errors}
          errorMessage="This field is required."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.firstName : ""}
        />

        <InputText
          required
          label="Surname"
          register={register}
          errors={errors}
          errorMessage="This field is required."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.lastName : ""}
        />

        <InputText
          label="Email"
          register={register}
          errors={errors}
          pattern={
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          }
          errorMessage="This does not appear to be a valid email address."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.email : ""}
        />

        <InputText
          name="Telephone"
          label="Telephone Number"
          register={register}
          errors={errors}
          errorMessage="This field is required."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.telephone : ""}
        />

        <InputText
          required
          label="Address Line 1"
          name="Address 1"
          register={register}
          errors={errors}
          errorMessage="This field is required."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.address1 : ""}
        />

        <InputText
          required
          label="Address Line 2"
          name="Address 2"
          register={register}
          errors={errors}
          errorMessage="This field is required."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.address2 : ""}
        />

        <InputText
          label="Postcode"
          register={register}
          errors={errors}
          required
          disabled={!!state.context.postcode}
          errorMessage="This field is required."
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.postcode : ""}
        />

        <InputRadio
          options={[INITIAL_STATE.payElectricAccount, "Gas"]}
          defaultChoiceIndex={0}
          name="payElectricAccount"
          question="Would you like your Warm Home Discount paid to your gas or electricity account?
        Please note: If you have a traditional prepayment meter we will automatically be paid to your electricity meter."
          register={register}
        />
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
